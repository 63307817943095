import {
  Account,
  Authentication,
  Cart,
  Checkout,
  Countries,
  DigitalAssets,
  Menus,
  Order,
  Pages,
  Products,
  Taxons,
  Vendors,
  Wishlists,
} from './endpoints';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Store } from './endpoints/Store';

@Injectable({ providedIn: 'root' })
export class SpreeService {
  private readonly http = inject(HttpClient);

  account = new Account(this.http);
  authentication = new Authentication(this.http);
  cart = new Cart(this.http);
  checkout = new Checkout(this.http);
  countries = new Countries(this.http);
  digitalAssets = new DigitalAssets(this.http);
  menus = new Menus(this.http);
  order = new Order(this.http);
  pages = new Pages(this.http);
  products = new Products(this.http);
  store = new Store(this.http);
  taxons = new Taxons(this.http);
  vendors = new Vendors(this.http);
  wishlists = new Wishlists(this.http);
}
