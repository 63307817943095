import { trimTrailingSlash } from '../app/helpers/general';

const host = import.meta.env.NG_APP_HOST;
const apiUrl = import.meta.env.NG_APP_API_URL;
const olzaApiUrl = import.meta.env.NG_APP_OLZA_API_URL;
const olzaToken = import.meta.env.NG_APP_OLZA_ACCESS_TOKEN;

export const environment = {
  production: true,
  host: trimTrailingSlash(host),
  apiUrl: trimTrailingSlash(apiUrl),
  storesApiUrl: import.meta.env.NG_APP_STORES_API_URL,
  olzaApiUrl,
  olzaToken,
};
