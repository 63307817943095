/* eslint-disable @typescript-eslint/no-explicit-any */
import { Http, squashAndPreparePositionalArguments } from '../../core';
import type { IToken, NoContentResponse } from '../../core';
import type {
  WishedItem,
  WishlistsAddWishedItem,
  WishlistsUpdateWishedItem,
  AddWishedItemOptions,
  UpdateWishedItemOptions,
  RemoveWishedItemOptions,
} from '../interfaces/WishedItem';
import type {
  WishlistsList,
  Wishlists as WishlistsResponse,
  Wishlist as WishlistResponse,
  WishlistsShow,
  WishlistsDefault,
  WishlistsCreate,
  WishlistsUpdate,
  ListOptions,
  ShowOptions,
  DefaultOptions,
  CreateOptions,
  UpdateOptions,
  RemoveOptions,
} from '../interfaces/Wishlist';
import routes from '../routes';
import { Observable } from 'rxjs';

export default class Wishlists extends Http {
  /**
   * Returns a list of Wishlists. See [api docs](https://api.spreecommerce.org/docs/api-v2/2b6c6c347d14b-list-all-wishlists).
   *
   * **Required token:** [Bearer token](../pages/tokens.html#bearer-token)
   *
   * **Options schema:**
   * ```ts
   * interface options {
   *   is_variant_included?: string
   * }
   * ```
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const response = client.wishlists.list({
   *   bearer_token: '7381273269536713689562374856',
   *   is_variant_included: '456'
   * })
   * ```
   */
  public list(options: ListOptions): Observable<WishlistsResponse>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public list(token: IToken, params?: WishlistsList): Observable<WishlistsResponse>;
  public list(...allArguments: any[]): Observable<WishlistsResponse> {
    const [tokenOrOptions, positionalParams = {}] = allArguments;
    const { token, params } = squashAndPreparePositionalArguments(
      [tokenOrOptions, positionalParams],
      []
    );

    return this.spreeResponse<WishlistsResponse>(
      'GET',
      routes.wishlistsPath(),
      token,
      params
    );
  }

  /**
   * Returns a single Wishlist. See [api docs](https://api.spreecommerce.org/docs/api-v2/b3A6MjE0NTY5NDA-retrieve-a-wishlist).
   *
   * **Required token:** [Bearer token](../pages/tokens.html#bearer-token)
   *
   * **Options schema:**
   * ```ts
   * interface options {
   *   wishlist_token: string
   *   is_variant_included?: string
   * }
   * ```
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const response = client.wishlists.show({
   *   bearer_token: '7381273269536713689562374856',
   *   wishlist_token: '123',
   *   is_variant_included: '456'
   * })
   * ```
   */
  public show(options: ShowOptions): Observable<WishlistResponse>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public show(
    token: IToken,
    wishlistToken: string,
    params?: WishlistsShow
  ): Observable<WishlistResponse>;
  public show(...allArguments: any[]): Observable<WishlistResponse> {
    const [tokenOrOptions, positionalWishlistToken, positionalParams = {}] = allArguments;
    const { wishlist_token, token, params } = squashAndPreparePositionalArguments(
      [tokenOrOptions, { wishlist_token: positionalWishlistToken }, positionalParams],
      ['wishlist_token']
    );

    return this.spreeResponse<WishlistResponse>(
      'GET',
      routes.wishlistPath(wishlist_token),
      token,
      params
    );
  }

  /**
   * Returns the default Wishlist for the logged in user. It will be created, if the user does not have a default Wishlist for the current store. See [api docs](https://api.spreecommerce.org/docs/api-v2/f29e11140c53c-retrieve-the-default-wishlist).
   *
   * **Required token:** [Bearer token](../pages/tokens.html#bearer-token)
   *
   * **Options schema:**
   * ```ts
   * interface options {
   *   is_variant_included?: string
   * }
   * ```
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const response = client.wishlists.default({
   *   bearer_token: '7381273269536713689562374856',
   *   is_variant_included: '456'
   * })
   * ```
   */
  public default(options: DefaultOptions): Observable<WishlistResponse>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public default(token: IToken, params?: WishlistsDefault): Observable<WishlistResponse>;
  public default(...allArguments: any[]): Observable<WishlistResponse> {
    const [tokenOrOptions, positionalParams = {}] = allArguments;
    const { token, params } = squashAndPreparePositionalArguments(
      [tokenOrOptions, positionalParams],
      []
    );

    return this.spreeResponse<WishlistResponse>(
      'GET',
      routes.defaultWishlistPath(),
      token,
      params
    );
  }

  /**
   * Creates a new Wishlist for the logged in user.
   *
   * **Required token:** [Bearer token](../pages/tokens.html#bearer-token)
   *
   * **Options schema:**
   * ```ts
   * interface options {
   *   name: string
   *   is_private?: boolean
   *   is_default?: boolean
   * }
   * ```
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const response = client.wishlists.create({
   *   bearer_token: '7381273269536713689562374856',
   *   name: 'My wishlist'
   * })
   * ```
   */
  public create(options: CreateOptions): Observable<WishlistResponse>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public create(token: IToken, params: WishlistsCreate): Observable<WishlistResponse>;
  public create(...allArguments: any[]): Observable<WishlistResponse> {
    const [tokenOrOptions, positionalParams] = allArguments;
    const { token, params } = squashAndPreparePositionalArguments(
      [tokenOrOptions, positionalParams],
      []
    );

    return this.spreeResponse<WishlistResponse>(
      'POST',
      routes.wishlistsPath(),
      token,
      params
    );
  }

  /**
   * Updates an existing Wishlist.
   *
   * **Required token:** [Bearer token](../pages/tokens.html#bearer-token)
   *
   * **Options schema:**
   * ```ts
   * interface options {
   *   wishlist_token: string
   *   name: string
   *   is_private?: boolean
   *   is_default?: boolean
   * }
   * ```
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const response = client.wishlists.update({
   *   bearer_token: '7381273269536713689562374856',
   *   wishlist_token: '123',
   *   name: 'My updated wishlist',
   *   is_private: true
   * })
   * ```
   */
  public update(options: UpdateOptions): Observable<WishlistResponse>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public update(
    token: IToken,
    wishlistToken: string,
    params: WishlistsUpdate
  ): Observable<WishlistResponse>;
  public update(...allArguments: any[]): Observable<WishlistResponse> {
    const [tokenOrOptions, positionalWishlistToken, positionalParams] = allArguments;
    const { wishlist_token, token, params } = squashAndPreparePositionalArguments(
      [tokenOrOptions, { wishlist_token: positionalWishlistToken }, positionalParams],
      ['wishlist_token']
    );

    return this.spreeResponse<WishlistResponse>(
      'PATCH',
      routes.wishlistPath(wishlist_token),
      token,
      params
    );
  }

  /**
   * Removes a Wishlist. See [api docs](https://api.spreecommerce.org/docs/api-v2/74e84b03b47e0-delete-a-wishlist).
   *
   * **Required token:** [Bearer token](../pages/tokens.html#bearer-token)
   *
   * **Options schema:**
   * ```ts
   * interface options {
   *   wishlist_token: string
   * }
   * ```
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const response = client.wishlists.remove({
   *   bearer_token: '7381273269536713689562374856',
   *   wishlist_token: '123'
   * })
   * ```
   */
  public remove(options: RemoveOptions): Observable<NoContentResponse>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public remove(token: IToken, wishlistToken: string): Observable<NoContentResponse>;
  public remove(...allArguments: any[]): Observable<NoContentResponse> {
    const [tokenOrOptions, positionalWishlistToken] = allArguments;
    const { wishlist_token, token, params } = squashAndPreparePositionalArguments(
      [tokenOrOptions, { wishlist_token: positionalWishlistToken }],
      ['wishlist_token']
    );

    return this.spreeResponse<NoContentResponse>(
      'DELETE',
      routes.wishlistPath(wishlist_token),
      token,
      params
    );
  }

  /**
   * Adds a new Wished Item to a Wishlist for the logged in user. See [api docs](https://api.spreecommerce.org/docs/api-v2/486219cd63ea9-add-item-to-wishlist).
   *
   * **Required token:** [Bearer token](../pages/tokens.html#bearer-token)
   *
   * **Options schema:**
   * ```ts
   * interface options {
   *   wishlist_token: string,
   *   variant_id: string
   *   quantity: number
   * }
   * ```
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const response = client.wishlists.addWishedItem({
   *   bearer_token: '7381273269536713689562374856',
   *   wishlist_token: 'WyZxWS2w3BdDRHcGgtN1LKiY',
   *   variant_id: '1',
   *   quantity: 10
   * })
   * ```
   */
  public addWishedItem(options: AddWishedItemOptions): Observable<WishedItem>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public addWishedItem(
    token: IToken,
    wishlistToken: string,
    params: WishlistsAddWishedItem
  ): Observable<WishedItem>;
  public addWishedItem(...allArguments: any[]): Observable<WishedItem> {
    const [tokenOrOptions, positionalWishlistToken, positionalParams] = allArguments;
    const { wishlist_token, token, params } = squashAndPreparePositionalArguments(
      [tokenOrOptions, { wishlist_token: positionalWishlistToken }, positionalParams],
      ['wishlist_token']
    );

    return this.spreeResponse<WishedItem>(
      'POST',
      routes.wishlistsAddWishedItemPath(wishlist_token),
      token,
      params
    );
  }

  /**
   * Updates a Wished Item for the logged in user. See [api docs](https://api.spreecommerce.org/docs/api-v2/e6e478e46003d-set-wished-item-quantity).
   *
   * **Required token:** [Bearer token](../pages/tokens.html#bearer-token)
   *
   * **Options schema:**
   * ```ts
   * interface options {
   *   wishlist_token: string,
   *   id: string
   *   quantity: number
   * }
   * ```
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const response = client.wishlists.updateWishedItem({
   *   bearer_token: '7381273269536713689562374856',
   *   wishlist_token: 'WyZxWS2w3BdDRHcGgtN1LKiY',
   *   id: '2',
   *   quantity: 13
   * })
   * ```
   */
  public updateWishedItem(options: UpdateWishedItemOptions): Observable<WishedItem>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public updateWishedItem(
    token: IToken,
    wishlistToken: string,
    id: string,
    params: WishlistsUpdateWishedItem
  ): Observable<WishedItem>;
  public updateWishedItem(...allArguments: any[]): Observable<WishedItem> {
    const [tokenOrOptions, positionalWishlistToken, positionalId, positionalParams] =
      allArguments;
    const { wishlist_token, id, token, params } = squashAndPreparePositionalArguments(
      [
        tokenOrOptions,
        { wishlist_token: positionalWishlistToken },
        { id: positionalId },
        positionalParams,
      ],
      ['wishlist_token', 'id']
    );

    return this.spreeResponse<WishedItem>(
      'PATCH',
      routes.wishlistsUpdateWishedItemQuantityPath(wishlist_token, id),
      token,
      params
    );
  }

  /**
   * Removes a Wished Item for the logged in user. See [api docs](https://api.spreecommerce.org/docs/api-v2/766b11755bbb0-delete-item-from-wishlist).
   *
   * **Required token:** [Bearer token](../pages/tokens.html#bearer-token)
   *
   * **Options schema:**
   * ```ts
   * interface options {
   *   wishlist_token: string,
   *   id: string
   * }
   * ```
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const response = client.wishlists.removeWishedItem({
   *   bearer_token: '7381273269536713689562374856',
   *   wishlist_token: 'WyZxWS2w3BdDRHcGgtN1LKiY',
   *   id: '2'
   * })
   * ```
   */
  public removeWishedItem(options: RemoveWishedItemOptions): Observable<WishedItem>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public removeWishedItem(
    token: IToken,
    wishlistToken: string,
    id: string
  ): Observable<WishedItem>;
  public removeWishedItem(...allArguments: any[]): Observable<WishedItem> {
    const [tokenOrOptions, positionalWishlistToken, positionalId] = allArguments;
    const { wishlist_token, id, token, params } = squashAndPreparePositionalArguments(
      [tokenOrOptions, { wishlist_token: positionalWishlistToken }, { id: positionalId }],
      ['wishlist_token', 'id']
    );

    return this.spreeResponse<WishedItem>(
      'DELETE',
      routes.wishlistsRemoveWishedItemPath(wishlist_token, id),
      token,
      params
    );
  }
}
