import { Http } from '../../core';
import routes from '../routes';
import { Observable } from 'rxjs';
import { IStore } from '../interfaces/Store';

export class Store extends Http {
  public show(): Observable<IStore> {
    return this.spreeResponse<IStore>('GET', routes.storePath());
  }
}
