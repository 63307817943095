/* eslint-disable @typescript-eslint/no-explicit-any */
import { Observable } from 'rxjs';
import type { IQuery } from '../../core';
import { Http, squashAndPreparePositionalArguments } from '../../core';
import type { IPages, ListOptions, ShowOptions } from '../interfaces/Page';
import routes from '../routes';
import { CmsPageApiResponse } from '../interfaces/Cms';

export default class Pages extends Http {
  /**
   * Returns a list of all CMS Pages available in the current store. See [api docs](https://api.spreecommerce.org/docs/api-v2/48dab6913cd0d-list-all-cms-pages).
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const pages = client.pages.list()
   * ```
   */
  public list(options?: ListOptions): Observable<IPages>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public list(params?: IQuery): Observable<IPages>;
  public list(...allArguments: any[]): Observable<IPages> {
    const [paramsOrOptions = {}] = allArguments;
    const { token, params } = squashAndPreparePositionalArguments([paramsOrOptions], []);

    return this.spreeResponse<IPages>('GET', routes.pagesPath(), token, params);
  }

  /**
   * Returns a single CMS Page. You can use either a CMS Page slug or ID. See [api docs](https://api.spreecommerce.org/docs/api-v2/cedb218a94c4d-retrieve-a-cms-page).
   *
   * **Options schema:**
   * ```ts
   * interface options {
   *   id: string
   * }
   * ```
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const page = client.pages.show({
   *   id: 'about-us'
   * })
   * ```
   */
  public show(options: ShowOptions): Observable<CmsPageApiResponse>;
  public show(...allArguments: any[]): Observable<CmsPageApiResponse> {
    const [idOrOptions, positionalParams = {}] = allArguments;
    const { id, token, params } = squashAndPreparePositionalArguments(
      [
        typeof idOrOptions === 'object' ? idOrOptions : { id: idOrOptions },
        positionalParams,
      ],
      ['id']
    );

    return this.spreeResponse<CmsPageApiResponse>(
      'GET',
      routes.pagePath(id),
      token,
      params
    );
  }
}
