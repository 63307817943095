/* eslint-disable @typescript-eslint/no-explicit-any */
import type { IQuery } from '../../core';
import { Http, squashAndPreparePositionalArguments } from '../../core';
import type { ITaxon, ITaxons, ListOptions, ShowOptions } from '../interfaces/Taxon';
import routes from '../routes';
import { Observable } from 'rxjs';
import { withCache } from '@ngneat/cashew';

export default class Taxons extends Http {
  /**
   * Returns a list of Taxons. See [api docs](https://api.spreecommerce.org/docs/api-v2/b3A6MzE0Mjc2NA-list-all-taxons).
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const response = client.taxons.list()
   * ```
   */
  public list(options?: ListOptions): Observable<ITaxons>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public list(params?: IQuery): Observable<ITaxons>;
  public list(...allArguments: any[]): Observable<ITaxons> {
    const [paramsOrOptions = {}] = allArguments;
    const { token, params } = squashAndPreparePositionalArguments([paramsOrOptions], []);

    return this.spreeResponse<ITaxons>('GET', routes.taxonsPath(), token, params);
  }

  /**
   * Returns a single Taxon. See [api docs](https://api.spreecommerce.org/docs/api-v2/6e26f7594be8b-retrieve-a-taxon).
   *
   * **Options schema:**
   * ```ts
   * interface options {
   *   id: string
   * }
   * ```
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const products = client.taxons.show({ id: '1' })
   * ```
   */
  public show(options: ShowOptions): Observable<ITaxon>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public show(id: string, params?: IQuery): Observable<ITaxon>;
  public show(...allArguments: any[]): Observable<ITaxon> {
    const [idOrOptions, positionalParams = {}] = allArguments;
    const { id, token, params } = squashAndPreparePositionalArguments(
      [
        typeof idOrOptions === 'object' ? idOrOptions : { id: idOrOptions },
        positionalParams,
      ],
      ['id']
    );

    return this.spreeResponse<ITaxon>(
      'GET',
      routes.taxonPath(id),
      token,
      params,
      'json',
      {},
      withCache()
    );
  }
}
