import {
  BasicSpreeError,
  ExpandedSpreeError,
  MisconfigurationError,
  NoResponseError,
  SpreeError,
  SpreeSDKError,
} from './errors';
import {
  HttpClient,
  HttpContext,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
  HttpParamsOptions,
} from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ErrorType, HttpMethod, IToken, JsonApiResponse } from './interfaces';
import { ValueOf } from '../../../helpers/types';

type HttpParamsObject = HttpParamsOptions['fromObject'];
type HttpParamsObjectValue = ValueOf<HttpParamsObject>;
type SpreeResponseParams = Record<
  string,
  HttpParamsObjectValue | Record<string, HttpParamsObjectValue>
>;

export default class Http {
  protected apiHost = environment.apiUrl;

  constructor(private http: HttpClient) {
    if (!this.apiHost.endsWith('/')) {
      this.apiHost += '/';
    }
  }

  protected spreeResponse<ResponseType = JsonApiResponse>(
    method: HttpMethod,
    url: string,
    tokens: IToken = {},
    params: SpreeResponseParams = {},
    responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json',
    body = {},
    context?: HttpContext
  ): Observable<ResponseType> {
    const headers = this.spreeOrderHeaders(tokens);

    return this.http
      .request(method, this.apiHost + url, {
        headers: new HttpHeaders(headers),
        params: this.createFlattenedParams(params),
        body: method === 'GET' ? undefined : body,
        responseType,
        context,
      })
      .pipe(
        catchError((error) => {
          throw error;
        })
      );
  }

  private createFlattenedParams(params: SpreeResponseParams): HttpParams {
    return new HttpParams({ fromObject: this.flattenParams(params) });
  }

  private flattenParams(params: object, parentKey?: string): HttpParamsObject {
    let flattenedParams: HttpParamsObject = {};

    Object.entries(params).forEach(([key, value]) => {
      const currentKey = parentKey ? `${parentKey}[${key}]` : key;

      if (typeof value === 'object') {
        const nestedParams = this.flattenParams(value, currentKey);

        flattenedParams = { ...flattenedParams, ...nestedParams };
      } else {
        if (flattenedParams) {
          flattenedParams[currentKey] = value;
        }
      }
    });

    return flattenedParams;
  }

  /**
   * The HTTP error code returned by Spree is not indicative of its response shape.
   * This function determines the information provided by Spree and uses everything available.
   */
  protected classifySpreeError(error: HttpErrorResponse): ErrorType {
    const { error: errorSummary, errors } = error.error;

    if (typeof errorSummary === 'string') {
      if (typeof errors === 'object') {
        return 'full';
      }

      return 'basic';
    }

    return 'limited';
  }

  protected processError(error: HttpErrorResponse): SpreeSDKError {
    if (error.error.response || error.error.request) {
      if (error.error.response) {
        // Error from Spree outside HTTP 2xx codes
        return this.processSpreeError(error);
      }

      if (error.error.request) {
        // No response received from Spree
        return new NoResponseError();
      }

      // Incorrect request setup
      return new MisconfigurationError(error.message);
    }

    return new SpreeSDKError(error.message);
  }

  protected processSpreeError(error: HttpErrorResponse): SpreeError {
    const { error: errorSummary, errors } = error.error;
    const errorType = this.classifySpreeError(error);

    if (errorType === 'full') {
      return new ExpandedSpreeError(error, errorSummary, errors);
    } else if (errorType === 'basic') {
      return new BasicSpreeError(error, errorSummary);
    } else {
      return new SpreeError(error);
    }
  }

  protected spreeOrderHeaders(tokens: IToken): { [headerName: string]: string } {
    const header = {} as { [headerName: string]: string };

    if (tokens.orderToken) {
      header['X-Spree-Order-Token'] = tokens.orderToken;
    }

    if (tokens.bearerToken) {
      header['Authorization'] = `Bearer ${tokens.bearerToken}`;
    }

    return header;
  }
}
