/* eslint-disable @typescript-eslint/no-explicit-any */
import type { EmptyObjectResponse, IOAuthToken } from '../../core';
import { Http, squashAndPreparePositionalArguments } from '../../core';
import type {
  AuthTokenAttr,
  GetTokenOptions,
  RefreshTokenAttr,
  RefreshTokenOptions,
  RevokeTokenAttr,
  RevokeTokenOptions,
} from '../interfaces/Authentication';
import { authParams, refreshParams, revokeParams } from '../helpers/auth';
import routes from '../routes';
import { Observable } from 'rxjs';

export default class Authentication extends Http {
  /**
   * Creates a [Bearer token](../pages/tokens.html#bearer-token) required to authorize OAuth API calls.
   *
   * **Success response schema:**
   * ```ts
   * interface res {
   *   access_token: string
   *   token_type: string = 'Bearer'
   *   expires_in: number
   *   refresh_token: string
   *   created_at: number
   * }
   * ```
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const token = client.authentication.getToken({
   *   username: 'spree@example.com',
   *   password: 'spree123'
   * })
   * ```
   */
  public getToken(options: GetTokenOptions): Observable<IOAuthToken>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public getToken(params: AuthTokenAttr): Observable<IOAuthToken>;
  public getToken(...allArguments: any[]): Observable<IOAuthToken> {
    const [paramsOrOptions] = allArguments;
    const { token, params } = squashAndPreparePositionalArguments([paramsOrOptions], []);

    return this.spreeResponse<IOAuthToken>(
      'POST',
      routes.oauthTokenPath(),
      token,
      authParams(params as AuthTokenAttr)
    );
  }

  /**
   * Refreshes the [Bearer token](../pages/tokens.html#bearer-token) required to authorize OAuth API calls.
   *
   * **Success response schema:**
   * ```ts
   * interface res {
   *   access_token: string
   *   token_type: string = 'Bearer'
   *   expires_in: number
   *   refresh_token: string
   *   created_at: number
   * }
   * ```
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const token = client.authentication.refreshToken({
   *   refresh_token: 'aebe2886d7dbba6f769e20043e40cfa3447e23ad9d8e82c632f60ed63a2f0df1'
   * })
   * ```
   */
  public refreshToken(options: RefreshTokenOptions): Observable<IOAuthToken>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public refreshToken(params: RefreshTokenAttr): Observable<IOAuthToken>;
  public refreshToken(...allArguments: any[]): Observable<IOAuthToken> {
    const [paramsOrOptions] = allArguments;
    const { token, params } = squashAndPreparePositionalArguments([paramsOrOptions], []);

    return this.spreeResponse<IOAuthToken>(
      'POST',
      routes.oauthTokenPath(),
      token,
      refreshParams(params as RefreshTokenAttr)
    );
  }

  /**
   * Revokes a [Bearer token (access token)](../pages/tokens.html#bearer-token) or a refresh token.
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const response = client.authentication.revokeToken({
   *   token: 'aebe2886d7dbba6f769e20043e40cfa3447e23ad9d8e82c632f60ed63a2f0df1'
   * })
   * ```
   */
  public revokeToken(optons: RevokeTokenOptions): Observable<EmptyObjectResponse>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public revokeToken(params: RevokeTokenAttr): Observable<EmptyObjectResponse>;
  public revokeToken(...allArguments: any[]): Observable<EmptyObjectResponse> {
    const [paramsOrOptions] = allArguments;
    const { token, params } = squashAndPreparePositionalArguments([paramsOrOptions], []);

    return this.spreeResponse<EmptyObjectResponse>(
      'POST',
      routes.oauthRevokePath(),
      token,
      revokeParams(params as RevokeTokenAttr)
    );
  }
}
