import { Inject, Injectable } from '@angular/core';
import { IS_BROWSER } from '../providers/is-platform.provider';

type ListItemNameKey = `${string}_item_list_name`;

export type LocalStorageKey =
  | ListItemNameKey
  | 'currency'
  | 'purchase'
  | 'chunkLoadErrorReloadTarget'
  | 'chunkLoadErrorReloadCount';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService implements Storage {
  private readonly localStorage: Storage;

  readonly type: 'native' | 'memory';

  constructor(@Inject(IS_BROWSER) isBrowser: boolean) {
    const { storage, type } = createStorage(isBrowser);
    this.localStorage = storage;
    this.type = type;
  }

  public get length(): number {
    return this.localStorage.length;
  }

  public clear(): void {
    return this.localStorage.clear();
  }

  public getItem(key: LocalStorageKey): string | null {
    return this.localStorage.getItem(key);
  }

  public key(index: number): string | null {
    return this.localStorage.key(index);
  }

  public removeItem(key: LocalStorageKey): void {
    return this.localStorage.removeItem(key);
  }

  public setItem(key: LocalStorageKey, value: string): void {
    return this.localStorage.setItem(key, value);
  }
}

class MemoryStorage implements Storage {
  private readonly data = new Map<string, unknown>();

  public get length(): number {
    return this.data.size;
  }

  public clear(): void {
    this.data.clear();
  }

  public removeItem(key: LocalStorageKey): void {
    this.data.delete(key);
  }

  public setItem(key: LocalStorageKey, value: unknown): void {
    this.data.set(key, value);
  }

  public getItem(key: LocalStorageKey): string | null {
    const value = this.data.get(key);

    return value ? JSON.stringify(value) : null;
  }

  public key(): string | null {
    return null;
  }
}

function createStorage(isBrowser: boolean): {
  storage: Storage;
  type: 'memory' | 'native';
} {
  return isLocalStorageAvailable(isBrowser)
    ? // eslint-disable-next-line no-restricted-globals
      { storage: localStorage, type: 'native' }
    : { storage: new MemoryStorage(), type: 'memory' };
}

function isLocalStorageAvailable(isBrowser: boolean): boolean {
  try {
    // eslint-disable-next-line no-restricted-globals
    return isBrowser && typeof localStorage !== 'undefined' && localStorage != null;
  } catch {
    return false;
  }
}
