/* eslint-disable @typescript-eslint/no-explicit-any */
import type { IProductsQuery, IToken } from '../../core';
import { Http, squashAndPreparePositionalArguments } from '../../core';
import type {
  IProduct,
  IProducts,
  ListOptions,
  ShowOptions,
} from '../interfaces/Product';
import routes from '../routes';
import { Observable } from 'rxjs';

export default class Products extends Http {
  /**
   * Returns a list of Products. See [api docs](https://api.spreecommerce.org/docs/api-v2/b3A6MzE0Mjc2Mg-list-all-products).
   *
   * **Required token:** [Bearer token](../pages/tokens.html#bearer-token) - if logged in user
   *
   * **Options schema:**
   * ```ts
   * interface options {
   *   image_transformation?: {
   *     size?: string
   *     quality?: number
   *   }
   * }
   * ```
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const response = client.products.list({
   *   page: 1,
   *   per_page: 10
   * })
   * ```
   */
  public list(options: ListOptions): Observable<IProducts>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public list(token: IToken, params: IProductsQuery): Observable<IProducts>;
  public list(...allArguments: any[]): Observable<IProducts> {
    const [tokenOrOptions = {}, positionalParams = {}] = allArguments;
    const { token, params } = squashAndPreparePositionalArguments(
      [tokenOrOptions, positionalParams],
      []
    );

    return this.spreeResponse<IProducts>('GET', routes.productsPath(), token, params);
  }

  /**
   * Returns a single product. See [api docs](https://api.spreecommerce.org/docs/api-v2/b3A6MTgwNTI4ODE-retrieve-a-product).
   *
   * **Required token:** [Bearer token](../pages/tokens.html#bearer-token) - if logged in user
   *
   * **Options schema:**
   * ```ts
   * interface options {
   *   image_transformation?: {
   *     size?: string
   *     quality?: number
   *   }
   * }
   * ```
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const response = client.products.show({
   *   id: '123',
   *   include: 'variants'
   * })
   * ```
   */
  public show(options: ShowOptions): Observable<IProduct>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public show(id: string, token: IToken, params: IProductsQuery): Observable<IProduct>;
  public show(...allArguments: any[]): Observable<IProduct> {
    const [idOrOptions, positionalToken = {}, positionalParams = {}] = allArguments;
    const { id, token, params } = squashAndPreparePositionalArguments(
      [
        typeof idOrOptions === 'object' ? idOrOptions : { id: idOrOptions },
        positionalToken,
        positionalParams,
      ],
      ['id']
    );

    return this.spreeResponse<IProduct>('GET', routes.productPath(id), token, params);
  }
}
