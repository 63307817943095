import { inject, Injectable } from '@angular/core';
import { StateService } from './state.service';
import { ProductListPage } from '../pages/product/product-list/product-list.types';
import { PaymentMethod, ShippingRate } from '../pages/checkout-page/checkout.service';
import { Variant } from '../pages/product/product-page/product.types';
import { ProductVariant } from '../pages/product/product-list/product-list.mapper';
import { Cart, LineItem } from '../pages/cart-page/cart.types';
import { LocalStorageKey, LocalStorageService } from './local-storage.service';
import { WINDOW } from '../providers/window.provider';
import { ExtendedWindow } from '../types/window';
import { SliderItem } from '../components/slider/slider.types';
import { toSha256Hex } from '../helpers/sha';

@Injectable({
  providedIn: 'root',
})
export class DataLayerService {
  private readonly state = inject(StateService);
  private readonly localStorage = inject(LocalStorageService);
  private readonly dl = inject<ExtendedWindow | null>(WINDOW)?.dataLayer;

  onViewItemList(page: ProductListPage): void {
    if (!this.dl) {
      return;
    }

    const currency = this.state.getActiveCurrency();
    this.dl.push({ ecommerce: null });
    this.dl.push({
      event: DlEvent.viewItemList,
      category,
      page_language: this.state.getActiveLocale(),
      ecommerce: {
        items: this.mapProductListToEventItems(page, currency),
      },
    });
  }

  onViewPromotion(slide: SliderItem, slot: number): void {
    if (!this.dl) {
      return;
    }

    this.dl.push({ ecommerce: null });
    this.dl.push({
      event: DlEvent.viewPromotion,
      category,
      page_language: this.state.getActiveLocale(),
      ecommerce: {
        creative_name: slide.label,
        creative_slot: `slot${slot}`,
        promotion_id: slide.promotionId,
        promotion_name: slide.promotionName,
      },
    });
  }

  onSelectPromotion(slide: SliderItem, slot: number): void {
    if (!this.dl) {
      return;
    }

    this.dl.push({ ecommerce: null });
    this.dl.push({
      event: DlEvent.selectPromotion,
      category,
      page_language: this.state.getActiveLocale(),
      ecommerce: {
        creative_name: slide.label,
        creative_slot: `slot${slot}`,
        promotion_id: slide.promotionId,
        promotion_name: slide.promotionName,
      },
    });
  }

  onSelectItem(listName: string, variant: ProductVariant, index: number): void {
    if (!this.dl) {
      return;
    }

    this.localStorage.setItem(productToLocalStorageKey(variant), listName);
    const currency = this.state.getActiveCurrency();

    this.dl.push({ ecommerce: null });
    this.dl.push({
      event: DlEvent.selectItem,
      category,
      page_language: this.state.getActiveLocale(),
      ecommerce: {
        items: [this.mapProductVariantToEventItem(variant, currency, index)],
      },
    });
  }

  onViewItem(variant: Variant): void {
    if (!this.dl) {
      return;
    }

    const currency = this.state.getActiveCurrency();

    this.dl.push({ ecommerce: null });
    this.dl.push({
      event: DlEvent.viewItem,
      category,
      page_language: this.state.getActiveLocale(),
      ecommerce: {
        value: variant.price.original,
        currency,
        items: [this.mapProductVariantToEventItem(variant, currency)],
      },
    });
  }

  onAddToCart(variant: Variant | LineItem, from: 'cart page' | 'product page'): void {
    if (!this.dl) {
      return;
    }

    const currency = this.state.getActiveCurrency();
    this.dl.push({ ecommerce: null });
    this.dl.push({
      event: DlEvent.addToCart,
      category,
      page_language: this.state.getActiveLocale(),
      button_place: from,
      ecommerce: {
        currency,
        value: variant.price.current,
        items: [this.mapProductVariantToEventItem(variant, currency)],
      },
    });
  }

  onCheckAvailability(variant: Variant): void {
    if (!this.dl) {
      return;
    }

    const currency = this.state.getActiveCurrency();
    this.dl.push({ ecommerce: null });
    this.dl.push({
      event: DlEvent.checkAvailability,
      category,
      page_language: this.state.getActiveLocale(),
      ecommerce: {
        currency,
        value: variant.price.current,
        items: [this.mapProductVariantToEventItem(variant, currency)],
      },
    });
  }

  onRemoveFromCart(
    variant: LineItem,
    from: 'cart page' | 'product page',
    quantity: number,
  ): void {
    if (!this.dl) {
      return;
    }

    const currency = this.state.getActiveCurrency();
    this.dl.push({ ecommerce: null });
    this.dl.push({
      event: DlEvent.removeFromCart,
      category,
      page_language: this.state.getActiveLocale(),
      button_place: from,
      ecommerce: {
        currency,
        value: variant.price.current * quantity,
        items: [this.mapLineItemToEventItem(variant, quantity, currency)],
      },
    });
  }

  onViewCart(cart: Cart): void {
    this.eventWithCart(DlEvent.viewCart, cart);
  }

  onBeginCheckout(cart: Cart): void {
    this.eventWithCart(DlEvent.beginCheckout, cart);
  }

  onCheckoutAddressPage(cart: Cart): void {
    this.eventWithCart(DlEvent.checkoutAddressPage, cart);
  }

  onCheckoutDeliveryPage(cart: Cart): void {
    this.eventWithCart(DlEvent.checkoutDeliveryPage, cart);
  }

  onAddShippingInfo(cart: Cart, shippingName: string): void {
    this.eventWithCart(DlEvent.addShippingInfo, cart, { shipping_tier: shippingName });
  }

  onCheckoutPaymentPage(cart: Cart): void {
    this.eventWithCart(DlEvent.checkoutPaymentPage, cart);
  }

  onAddPaymentInfo(cart: Cart, payment: PaymentMethod): void {
    this.eventWithCart(DlEvent.addPaymentInfo, cart, { payment_type: payment.type });
  }

  async onBuyButton(
    cart: Cart,
    orderId: string,
    payment: PaymentMethod,
    shipping: ShippingRate,
  ): Promise<void> {
    await this.purchaseEvent(DlEvent.buyButton, cart, orderId, payment, shipping);
    this.localStorage.setItem(
      'purchase',
      JSON.stringify({
        cart,
        orderId,
        payment,
        shipping,
      }),
    );
  }

  async onPurchase(): Promise<void> {
    const savedData = this.localStorage.getItem('purchase');

    if (!savedData) {
      return;
    }

    const { cart, orderId, payment, shipping } = JSON.parse(savedData);

    await this.purchaseEvent(DlEvent.purchase, cart, orderId, payment, shipping);
    this.localStorage.removeItem('purchase');
  }

  onSearch(term: string): void {
    if (!this.dl || !term) {
      return;
    }

    this.dl.push({
      event: DlEvent.search,
      category: 'other events',
      page_language: this.state.getActiveLocale(),
      search_term: term.trim().toLowerCase(),
    });
  }

  onSort(label: string, listName: string): void {
    if (!this.dl) {
      return;
    }

    this.dl.push({
      event: DlEvent.sortProducts,
      category: 'other events',
      page_language: this.state.getActiveLocale(),
      action: 'click',
      label,
      list_name: listName,
    });
  }

  onFilter(
    label: string,
    filterCategory: string,
    action: 'check' | 'apply filter click',
    listName: string,
  ): void {
    if (!this.dl) {
      return;
    }

    this.dl.push({
      event: DlEvent.filterProducts,
      category: 'other events',
      page_language: this.state.getActiveLocale(),
      action,
      label,
      list_name: listName,
      filter_category: filterCategory,
    });
  }

  private async purchaseEvent(
    event: typeof DlEvent.purchase | typeof DlEvent.buyButton,
    cart: Cart,
    orderId: string,
    payment: PaymentMethod,
    shipping: ShippingRate,
  ): Promise<void> {
    if (!this.dl) {
      return;
    }

    const userData = await this.getUserData(cart);
    const currency = this.state.getActiveCurrency();

    this.dl.push({ ecommerce: null });
    this.dl.push({
      event,
      category,
      page_language: this.state.getActiveLocale(),
      payment_type: payment.type,
      shipping_tier: shipping.name,
      ...userData,
      ecommerce: {
        transaction_id: orderId,
        value: cart.itemTotalAmount,
        tax: cart.taxTotalAmount,
        shipping: cart.shipTotalAmount,
        currency,
        items: cart.lineItems.map((li) =>
          this.mapLineItemToEventItem(li, li.quantity, currency),
        ),
      },
    });
  }

  private async getUserData(cart: Cart): Promise<object> {
    if (!(typeof crypto?.subtle?.digest === 'function')) {
      return {};
    }

    return {
      user_data: {
        sha256_email_address: await toSha256Hex(cart.email),
        sha156_phone_number: await toSha256Hex(
          cart.address.billing.phone.replaceAll(' ', ''),
        ),
        address: {
          sha256_first_name: await toSha256Hex(cart.address.billing.firstName),
          sha256_last_name: await toSha256Hex(cart.address.billing.lastName),
          street: cart.address.billing.addressLine1,
          city: cart.address.billing.city,
          postal_code: cart.address.billing.postalCode,
          country: cart.address.billing.country,
        },
      },
    };
  }

  private eventWithCart(
    event: DlEventName,
    cart: Cart,
    rest: Record<string, string | number> = {},
  ): void {
    if (!this.dl) {
      return;
    }

    const currency = this.state.getActiveCurrency();
    this.dl.push({ ecommerce: null });
    this.dl.push({
      event,
      category,
      page_language: this.state.getActiveLocale(),
      ecommerce: {
        currency: this.state.getActiveCurrency(),
        value: cart.itemTotalAmount,
        items: cart.lineItems.map((li) =>
          this.mapLineItemToEventItem(li, li.quantity, currency),
        ),
        ...rest,
      },
    });
  }

  private mapProductListToEventItems = (
    page: ProductListPage,
    currency: string,
  ): unknown[] => {
    return page.products.map((variant, index) => {
      const categories: Record<string, string> = {};

      if (variant.categories) {
        const categoriesCount = Math.min(variant.categories.length, 5);

        for (let i = 1; i <= categoriesCount; i++) {
          const categoryName = 'item_category' + (i > 1 ? i : '');
          categories[categoryName] = variant.categories[i].name;
        }
      }

      return {
        item_id: variant.sku,
        item_name: variant.name,
        discount: variant.price.original - variant.price.current,
        item_list_name: page.meta.category.name,
        price: variant.price.current,
        item_brand: 'YES',
        index,
        currency,
        ...categories,
      };
    });
  };

  private mapLineItemToEventItem = (
    position: LineItem,
    quantity: number,
    currency: string,
  ): unknown => {
    return {
      item_id: position.sku,
      item_name: position.name,
      discount: position.price.original - position.price.current,
      price: position.price.current,
      item_list_name: this.getProductListName(position),
      item_brand: 'YES',
      currency,
      quantity,
      ...this.mapCategories(position),
    };
  };

  private mapProductVariantToEventItem(
    product: Variant | LineItem,
    currency: string,
    index?: number,
    quantity = 1,
  ): unknown {
    return {
      item_id: product.sku,
      item_name: product.name,
      discount: product.price.original - product.price.current,
      price: product.price.current,
      item_list_name: this.getProductListName(product),
      item_brand: 'YES',
      currency,
      index,
      quantity,
      ...this.mapCategories(product),
    };
  }

  private mapCategories(product: Variant | LineItem): Record<string, string> {
    const cats: Record<string, string> = {};

    if (!product.categories) {
      return cats;
    }

    product.categories.forEach(({ name }, index) => {
      const catIndex = index + 1;
      const catName = 'item_category' + (catIndex > 1 ? catIndex : '');
      cats[catName] = name;
    });

    return cats;
  }

  private getProductListName(variant: Variant | LineItem): string {
    return (
      this.localStorage.getItem(productToLocalStorageKey(variant)) || 'product details'
    );
  }
}

function productToLocalStorageKey(product: Variant | LineItem): LocalStorageKey {
  return `${product.productId}_item_list_name`;
}

const DlEvent = {
  viewPromotion: 'view_promotion',
  selectPromotion: 'select_promotion',
  viewItemList: 'view_item_list',
  selectItem: 'select_item',
  viewItem: 'view_item',
  addToCart: 'add_to_cart',
  checkAvailability: 'check_availability',
  removeFromCart: 'remove_from_cart',
  addToWishlist: 'add_to_wishlist',
  viewCart: 'view_cart',
  beginCheckout: 'begin_checkout',
  checkoutAddressPage: 'checkout_address_page',
  checkoutDeliveryPage: 'checkout_delivery_page',
  addShippingInfo: 'add_shipping_info',
  checkoutPaymentPage: 'checkout_payment_page',
  addPaymentInfo: 'add_payment_info',
  buyButton: 'buy_button',
  purchase: 'purchase',
  search: 'search',
  filterProducts: 'filter_products',
  sortProducts: 'sort_products',
} as const;

type DlEventName = (typeof DlEvent)[keyof typeof DlEvent];

const category = 'ecommerce';
