/* eslint-disable @typescript-eslint/no-explicit-any */
import { Observable } from 'rxjs';
import type { IQuery } from '../../core';
import { Http, squashAndPreparePositionalArguments } from '../../core';
import type {
  DefaultOptions,
  ICountries,
  ICountry,
  ListOptions,
  ShowOptions,
} from '../interfaces/Country';
import routes from '../routes';
import { withCache } from '@ngneat/cashew';

export default class Countries extends Http {
  /**
   * Returns a list of all countries. See [api docs](https://api.spreecommerce.org/docs/api-v2/ca56911efbaab-list-all-countries).
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const countries = client.countries.list()
   * ```
   */
  public list(options?: ListOptions): Observable<ICountries> {
    const { token, params } = squashAndPreparePositionalArguments([options || {}], []);

    return this.spreeResponse<ICountries>(
      'GET',
      routes.countriesPath(),
      token,
      params,
      'json',
      {},
      withCache()
    );
  }

  /**
   * Returns the details of a specific country. See [api docs](https://api.spreecommerce.org/docs/api-v2/5f5116adb3113-retrieve-a-country).
   *
   * **Options schema:**
   * ```ts
   * interface options {
   *   iso: string
   * }
   * ```
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const country = client.countries.show({
   *   iso: 'USA'
   * })
   * ```
   */
  public show(options: ShowOptions): Observable<ICountry>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public show(iso: string, params: IQuery): Observable<ICountry>;
  public show(...allArguments: any[]): Observable<ICountry> {
    const [isoOrOptions, positionalParams = {}] = allArguments;
    const { iso, token, params } = squashAndPreparePositionalArguments(
      [
        typeof isoOrOptions === 'object' ? isoOrOptions : { iso: isoOrOptions },
        positionalParams,
      ],
      ['iso']
    );

    return this.spreeResponse<ICountry>('GET', routes.countryPath(iso), token, params);
  }

  /**
   * Returns the default country for the current store. By default this will be the US. See [api docs](https://api.spreecommerce.org/docs/api-v2/7cf807c85c035-get-default-country).
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const countries = client.countries.default()
   * ```
   */
  public default(options?: DefaultOptions): Observable<ICountry>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public default(params: IQuery): Observable<ICountry>;
  public default(...allArguments: any[]): Observable<ICountry> {
    const [paramsOrOptions = {}] = allArguments;
    const { token, params } = squashAndPreparePositionalArguments([paramsOrOptions], []);

    return this.spreeResponse<ICountry>(
      'GET',
      routes.defaultCountryPath(),
      token,
      params
    );
  }
}
